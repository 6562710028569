#logo {
    max-width: 150px;
    max-height: 150px;
}
.navbar {
    background-color: #f2f2f2;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .navbar__slogan {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.4em;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 2px;
    background: linear-gradient(to bottom, #DEB887, #D2B48C); /* Lighter Cedar to Pine colors */
    color: #8B4513; /* Darker wood color for text */
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .navbar__logo a {
    text-decoration: none;
    color: #333;
  }
  
  .navbar__menu {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .navbar__list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    
  }
  
  .navbar__item {
    margin-right: 20px;
  }
  
  .navbar__item a {
    text-decoration: none;
    color: #8B4513;
    transition: color 0.3s ease;
  }

  .navbar__item a {
  text-decoration: none;
  color: #8B4513; /* Darker wood color */
  transition: color 0.3s ease, background-color 0.3s ease;
}

.navbar__item a:hover {
  color: #D2B48C; /* Lighter Pine color */

}

  .navbar__item a:hover {
    color: #6A994E; /* Update with your desired hover color */

  }
  
  .navbar__toggle {
    display: none;
    cursor: pointer;
  }
  
  .navbar__icon {
    width: 30px;
    height: 3px;
    background-color: #333;
    margin-bottom: 5px;
  }
  /* Monitor - Large Screen */
  @media (max-width: 768px) {
    .navbar__menu {
      display: none;
    }
  
    .navbar__toggle {
      display: block;
    }
  
    .navbar__menu.open {
      display: flex;
      flex-direction: column; /* Stack items vertically */
      align-items: center; /* Center items */
      max-height: 200px; /* Adjust as needed */
      transition: max-height 0.3s ease-out;
      padding-top: 20px; /* Add some space at the top */
    }
  
    .navbar__item {
      margin-right: 0;
      margin-bottom: 10px; /* Adjust vertical spacing */
      width: 100%; /* Stretch items to full width */
      text-align: center; /* Center text */
    }
  
    /* Transformations for hamburger icon */
    .navbar__toggle.open .navbar__icon:nth-child(1) {
      transform: rotate(45deg) translate(5px, 5px);
    }
  
    .navbar__toggle.open .navbar__icon:nth-child(2) {
      opacity: 0;
    }
  
    .navbar__toggle.open .navbar__icon:nth-child(3) {
      transform: rotate(-45deg) translate(5px, -5px);
    }
  }
  

  /* Tablets - Medium Screen Size */

  @media (min-width: 481px) and (max-width: 768px) {
    .navbar__item {
      padding: 10px;
    }
}
/* Mobile device - small screen */
  @media (max-width: 480px) {
    .navbar__list {
      flex-direction: column;
      align-items: center;
      list-style-type: none;
      margin: 0;
      padding: 0;
      display: flex;
      
    }

    .navbar__item {
        margin-right: 0;
        margin-bottom: 10px;
      }
}