.contact {
    text-align: center;
    padding: 20px;
  }
  
  .contact__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 20px;
  }
  
  .contact__info {
    flex-basis: 300px;
    align-items: center;
  }
  
  .contact__form {
    flex-basis: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .contact__form input,
  .contact__form textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
  }
  
  .contact__form button {
    padding: 10px 20px;
    background-color: #4caf50;
    color: #fff;
    border: none;
    cursor: pointer;
  }
  
  .contact__form button:hover {
    background-color: #45a049;
  }

  #map {
    border-radius: 10px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
    transition: opacity 0.3s ease-in-out;
  }

  #map:hover img {
    opacity: 0.8;
  }

  .contact-details {
    background-color: #F0F0F0;
    border: 2px solid #A9A9A9;
    padding: 10px;
    box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.2);
  }
  
  .contact-form {
    max-width: 600px; /* Adjust as needed */
    width: 80%;
    margin: auto;
    padding: 20px;
    border-radius: 8px; /* Optional */
    background-color: #F0F0F0;
    border: 2px solid #A9A9A9;
    padding: 10px;
    box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.2);
}

.contact-form p {
    margin-top: 16px;
    margin-bottom: 8px;
    font-weight: bold; /* Optional */
    
}

.contact-form input,
.contact-form textarea {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    margin-bottom: 16px; /* Space between fields */
    border: 1px solid #ddd; /* Light grey border */
    border-radius: 4px; /* Rounded corners */
}

.contact-form button {
    background-color: #007bff; /* Bootstrap primary color */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.contact-form button:hover {
    background-color: #0056b3; /* Darker on hover */
}

.success-message {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 4px;
  text-align: center;
}

/* In contact.css */
.textarea-large {
  width: 100%;
  height: 200px;
  resize: vertical;
}

/* In a global CSS file */
input, textarea {
  font-family: Arial, sans-serif;
  font-size: 14px;
  /* Other styles */
}



