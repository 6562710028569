.projects {
  text-align: center;
  padding: 20px;
}

._1eGao {
  background-color: #F0F0F0;
  border: 2px solid #A9A9A9;
  padding: 10px;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.2);
}

.projects__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.project {
  position: relative;
  flex-basis: calc(100% - 20px); /* 1 slide for small screens */
  overflow: hidden;
  margin: 5px;
}

.project img {
  width: 100%;
  height: auto;
  transition: transform 0.3s;
} 

/* .project:hover img {
  transform: scale(1.1);
} */

.project__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  opacity: 0;
  transition: opacity 0.3s;
}

.project:hover .project__overlay {
  opacity: 1;
}

.project__overlay h3 {
  font-size: 1.5rem;
}

/* 2 slides for medium screens */
@media screen and (min-width: 768px) {
  .project {
    flex-basis: calc(50% - 20px);
  }
  ._-LJ2W {
    background-color: #F0F0F0;
  }
}

/* 3 slides for large screens */
@media screen and (min-width: 1200px) {
  .project {
    flex-basis: calc(33.333% - 20px);
  }
  ._-LJ2W {
    background-color: #F0F0F0;
  }
}

/* CSS for smaller screens (e.g., screens 768px wide or smaller) */
@media screen and (max-width: 768px) {
  ._-LJ2W, ._2c50p, ._-cAh3 ._19gvi{
    display: none; /* Hide elements with these classes on small screens */
  }
  ._L8X8r {
    background-color: #F4E1C6;;
  }
}

.project-desc {
  background-color: #F0F0F0;
  border: 2px solid #A9A9A9;
  /* padding: 2.5px; */
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.2);
}

.project-style {
  border-radius: 10px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
  transition: opacity 0.3s ease-in-out;
}

.project-style:hover {
  opacity: 0.8;
}

.project-gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); /* Adjust the minmax values as needed */
  gap: 20px; /* Adjust the spacing between items */
}

.project img {
  width: 100%; /* Ensure images take full width of their container */
  height: auto; /* Maintain aspect ratio */
}

/* Add more styles as needed */
