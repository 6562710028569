.footer {
    background-color: #f2f2f2;
    padding: 20px;
  }
  
  .footer__content {
    display: flex;
    justify-content: space-between;
  }
  
  .footer__section {
    /* margin-right: 40px; */
    flex-basis: 33.3%;
    padding-right: 20px;
  }
  
  .footer h3 {
    margin-bottom: 10px;
  }
  
  .footer ul {
    padding: 0;
    list-style-type: none;
  }
  
  .footer__bottom {
    margin-top: 20px;
    text-align: center;
  }
  /* Add vertical spacing between list items */
.social-item {
  margin-bottom: 10px; /* Adjust the spacing as needed */
}

.social-item a:hover {
  color: #6A994E;
}

a {
  color: #8B4513;
  text-decoration: none;
}

a:visited {
  color: #8B4513;
}

#footer-about {
  background-color: #F0F0F0;
  border: 2px solid #A9A9A9;
  padding: 10px;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.2);
}