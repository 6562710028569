.services {
    text-align: center;
    padding: 20px;
  }

  #service-desc {
    background-color: #F0F0F0;
    border: 2px solid #A9A9A9;
    padding: 10px;
    box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.2);
  }
  
  .services__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  .service {
    flex-basis: 300px;  
  }
  
  .service img {
    width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
    transition: opacity 0.3s ease-in-out;
  }

  .service:hover img {
    opacity: 0.8;
  }

  #service-heading {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif

  }

  #service-title {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }

  @media (min-width: 992px) {
    .service {
      flex-basis: calc(33.33% - 20px);
    }
  }