h2 {
  background-image: url('../../images/stained_wood.jpg');
  background-size: cover; /* You can adjust this based on your preference */
  color: #6A994E; /* Choose a contrasting text color for readability */
  padding: 15px; /* Add padding to give space around the text */
}
h3 {
  color: #8B4513
}
svg {
  color: #707070
}

body {
  background-color: #F4E1C6; /* Light Brown */
}

#plywood {
  width: 400px;
  height: 300px;
}

.about-me {
    text-align: center;
    padding: 20px;
  }
  
  .about-me__content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  
  .about-me__image-container {
    flex: 0 0 300px;
    margin-right: 20px;
  }
  
  .about-me__image {
    width: 100%;
    height: auto;
  }
  
  .about-me__text {
    flex: 1;
  }

  #about-us {
    background-color: #F0F0F0;
    border: 2px solid #A9A9A9;
    padding: 10px;
    box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.2);
  }

  .image-container {
    display: flex;
    justify-content: space-evenly;
  }
  
  .image-container img {
    width: 30%;
    height: auto;
  }
  
  /* For small screens */
  @media (max-width: 480px) {
    .image-container {
      flex-direction: column;
      align-items: center;
    }
    .image-container img {
      width: 100%;
      margin-bottom: 20px; /* Add space between stacked images */
    }
    #basement {
      width: auto;
      height: 5vh;
    }
  }

  .about-me__content {
    display: flex;
    flex-wrap: wrap; /* Allow items to wrap to the next row on smaller screens */
  }
  
  .about-me__text {
    flex: 1; /* Allow the text to grow to fill available space */
    padding: 20px; /* Add spacing around the text */
  }
  
  .about-me__image-container {
    flex: 1; /* Allow the image container to grow to fill available space */
    display: flex;
    flex-wrap: wrap; /* Allow images to wrap to the next row on smaller screens */
    justify-content: space-evenly;
  }
  
  /* Style each image within the container */
  .about-me__image-container img {
    max-width: 100%; /* Ensure images do not exceed their container width */
    height: auto; /* Maintain aspect ratio */
    margin: 10px; /* Add spacing between images */
  }

  #raptor {
    width: 500px;
  }
  #jesus {
    width: 450px;
  }

  #vic {
    width: 450px;
  }

  .aboutMe-pics {
    border-radius: 10px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
    transition: opacity 0.3s ease-in-out;
  }

  .aboutMe-pics:hover {
    opacity: 0.8;
  }

  .navbar__slogan {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.4em;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 2px;
    background: linear-gradient(to bottom, #DEB887, #D2B48C); /* Lighter Cedar to Pine colors */
    color: #8B4513; /* Darker wood color for text */
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    width: 50%;
  }

  #basement {
    width: auto;
    height: 100vh;
  }
  
  
  
  
  
  
  